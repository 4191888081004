<template>
  <v-row class="ma-0" v-scroll="onScroll">
    <!-- welcome -->
    <v-col cols="6">
     <ax-welcome />
    </v-col>
    <!-- end welcome -->
      <v-spacer/>
      <v-col class="pr-8 pl-0 pt-0" cols="6">
        <div class="col-6 pr-8 background pt-10" style="position: fixed; z-index: 10;" :style="scroll > 100 ? 'box-shadow: 2px 4px 6px -6px rgba(69, 90, 100, 0.15);' : ''">
          <v-toolbar class="mb-2 transparent" flat height="46" min-width="100%">
            <v-toolbar-title class="text-h5 pa-0 font-weight-semibold">Organizaciones</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="end" no-gutters>
                <v-btn @click="deleteAuth" outlined :ripple="false"><v-icon left>mdi-exit-to-app</v-icon>Cerrar sesión</v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
          <v-divider />

          <!-- sub header -->
          <v-row class="mt-4 d-flex justify-sm-space-between" no-gutters>
            <v-col cols="6" class="pa-0 pr-4 text-left">
              <v-text-field v-model="filterAccountText" autocomplete="off" outlined placeholder="Buscar..." prepend-inner-icon="mdi-magnify" single-line dense maxlength="64" hide-details />
            </v-col>
            <v-spacer />
            <v-col class="pa-0 text-right">
              <v-btn :to="{ name: 'AuthSetup' }" color="primary"><v-icon left>mdi-bank-plus</v-icon>Crear organización</v-btn>
            </v-col>
          </v-row>
          <!-- end sub header -->
        </div>
      <!-- loader -->
      <v-row v-if="loading" style="margin-top: 170px">
        <v-col cols="6" class="pr-3 pl-6" v-for="item in 2" :key="item">
          <v-skeleton-loader type="card-heading" />
        </v-col>
      </v-row>
      <!-- end loader -->

      <v-row class="pa-0 pl-3" v-else-if="accounts.length" style="margin-top: 170px;">
        <v-col cols="6" lg="6" xl="4" v-for="d in accounts" :key="d.id">
          <v-lazy :value="page" :options="{ threshold: .5 }" transition="fade-transition">
            <v-hover v-slot="{ hover }">
              <v-card class="pa-0" :elevation="hover ? 6 : 0" @click="setAccount(d)" :ripple="false">
                <v-card-text class="py-4 py-5">
                  <div class="d-flex justify-sm-space-between">
                    <v-icon color="primary">mdi-domain</v-icon>
                  </div>
                  <span class="d-block text-truncate mt-4">
                    <span class="fontBody--text font-weight-medium">{{d.name}}</span>
                  </span>
                  <span class="d-block fontDraw--text">{{$t('generals.RUT')}} {{d.tax_id}}</span>
                </v-card-text>
              </v-card>
            </v-hover>
          </v-lazy>
        </v-col>
      </v-row>
      <v-row v-else-if="!accountsCount || (filterAccountText.length > 0 && filterAccountText.length < 3)" aling="center" justify="start" class="mx-0">
        <v-col style="margin-top: 190px; z-index: 100;">
          <v-empty-state title="No existen organizaciones que coincidan con tu búsqueda." type="organization" customClass="mt-6" :isFree="true" description="Modifica la búsqueda para obtener registros" :height="200"  />
        </v-col>
      </v-row>

      <v-row v-else aling="center" justify="start" class="mx-0">
        <v-col style="margin-top: 190px; z-index: 100;">
          <v-empty-state title="Tu cuenta de usuario no tiene una organización asociada, solicita una organización a soporte o crea una tu mismo haciendo clic en Crear una organización." type="organization" customClass="mt-6" :isFree="true" :height="200" />
        </v-col>
      </v-row>
      <div class="text-center pb-16">
        <v-progress-circular v-if="lazyLoading" indeterminate color="primary" :width="2" :size="26" />
      </div>
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import AxWelcome from '@/components/commons/AxWelcome'
import debounce from 'lodash/debounce'
import VEmptyState from '@/components/commons/VEmptyState'

export default {
  components: {
    AxWelcome,
    VEmptyState
  },
  data: () => ({
    page: 1,
    loading: false,
    lazyLoading: false,
    selectedCountry: [],
    filterAccountText: '',
    next: null,
    accounts: [],
    isScroll: false,
    scroll: 0,
    attrs: {
      boilerplate: true,
      elevation: 0
    }
  }),
  computed: {
    ...mapState({
      countriesList: state => state.base.countriesList,
      accountsCount: state => state.accounts.accountsCount,
      accountsList: state => state.accounts.accountsList
    })
  },
  watch: {
    filterAccountText: debounce(function () {
      if (this.filterAccountText.length > 0 && this.filterAccountText.length < 3) {
        return false
      }
      this.getAccounts()
    }, 600),
    $route () {
      this.filterAccountText = ''
      this.getInitialData()
    },
    selectedCountry (val) {
      if (val.length) this.$router.replace({ query: { country__in: val.toString() } }).catch(() => {})
      else this.$router.replace({ query: {} }).catch(() => {})
    }
  },
  created () {
    this.$store.commit('accounts/SET_ME', {})
    this.getInitialData()
  },
  methods: {
    getInitialData () {
      this.loading = true
      this.$store.dispatch('accounts/LIST', {
        resource: 'accounts',
        query: this.$route.query
      })
      .then((response) => {
        this.next = response.data.next
        this.accounts = [...response.data.results]
      })
      .finally(() => {
        this.loading = false
      })
    },
    onScroll (e) {
      this.scroll = e.target?.scrollingElement?.scrollTop ?? 0
      this.isScroll = (e.target.scrollingElement?.clientHeight + e.target.scrollingElement?.scrollTop) >= e.target.scrollingElement?.offsetHeight
      if (this.next && this.isScroll) {
        this.seeMore()
      }
    },
    seeMore () {
      this.page += 1
      if (this.accountsCount) {
        this.lazyLoading = true
        this.$store.dispatch('accounts/LIST', {
          resource: 'accounts',
          query: { page: this.page},
          loader: false
        })
        .then((response) => {
          this.next = response.data.next
          this.accounts = [...this.accounts, ...response.data.results]
          this.isScroll = false
          if (!this.next) this.page = 1
        })
        .finally(() => {
          // document.getElementById("list").scrollIntoView(true)
          this.lazyLoading = false
        })
      }
    },
    getAccounts () {
      const query = {}

      if (this.filterAccountText) {
        query.name__icontains = this.filterAccountText
      }
      this.loading = true
      this.$store.dispatch('accounts/LIST', {
        resource: 'accounts',
        query: query
      })
      .then((response) => {
        this.accounts = [...response.data.results]
      })
      .finally(() => {
        this.loading = false
      })
    },
    setAccount (account) {
      // track Segment page group
      if (process.env.NODE_ENV === 'production') {
        window.analytics.group(account.id, {
          name: account.name
        })
      }
      this.$store.dispatch('auth/SET_ACCOUNT', { account })
      if (!this.$store.state.auth.userAccount.is_staff && this.$store.state.accounts.me.id === 'undefined') {
        this.$store.dispatch('accounts/GET_ME', {
          resource: 'members/me/?expand=groups'
        })
      }
    },
    deleteAuth () {
      this.$store.dispatch('auth/DELETE_AUTH')
      this.$store.commit('accounts/SET_ME', {})
    }
  }
}
</script>